<template>
  <div class="overflow-hidden flex flex-col p-4 pipeline relative">
    <div class="flex items-center mb-2">
      <div class="module-title">Opportunity Management</div>

      <div
        class="rounded-full px-2 text-box ml-2 font-semibold"
        :style="'color:rgb(146, 227, 138);background-color:rgba(146, 227, 138, 0.133)'"
        v-if="$route.params.catId"
      >
        {{ $route.params.catId }}
      </div>

      <div class="ml-auto flex relative">
        <!-- <div v-if="!seeit" class="absolute top-0 left-0 h-full w-full z-20 rounded-lg" style="backdrop-filter: blur(10px);"></div> -->
        <router-link
          v-if="globalParamsPretty.rol.role_type_link !== 'EP'"
          :to="{ name: 'PipelineSLSMU' }"
          class="header-btn"
        >
          <i class="mdi mdi-view-split-vertical"></i>
        </router-link>
        <router-link :to="{ name: 'OpportunitiesStatus' }" class="header-btn">
          <i class="mdi mdi-google-circles"></i>
        </router-link>
        <router-link
          v-if="opportunity"
          :to="{ name: 'home' }"
          class="header-btn-gray"
        >
          <i class="mdi mdi-home"></i>
        </router-link>
        <router-link v-else :to="'OpportunitiesList'" class="header-btn">
          <i class="mdi mdi-view-list"></i>
        </router-link>
      </div>
    </div>

    <div class="h-full overflow-hidden flex flex-col relative">
      <div class="h-62 flex-none pb-2 relative">
        <div class="box h-full flex flex-col relative">
          <!-- <div v-if="!seeit" class="absolute top-0 left-0 h-full w-full z-20 rounded-lg" style="backdrop-filter: blur(10px);"></div> -->

          <pipeline-status />
          <pipeline-bubbles />
        </div>
      </div>

      <div class="flex flex-1 min-h-0 overflow-hidden pb-2 flex flex-col">
        <div class="box h-full flex flex-col relative flex flex-col">
          <!-- <div v-if="!seeit" class="absolute top-0 left-0 h-full w-full z-20 rounded-lg" style="backdrop-filter: blur(10px);"></div> -->

          <div class="flex items-center">
            <div class="box-title">Sales</div>
            <i
              v-if="
                isObjectivePercentage >= 100 &&
                isNotNaAndTbd(isObjectivePercentage)
              "
              class="mdi mdi-arrow-top-right text-success text-xl ml-auto"
            ></i>
            <i
              v-else-if="isNotNaAndTbd(isObjectivePercentage)"
              class="mdi mdi-arrow-bottom-left text-danger text-xl ml-auto"
            ></i>
          </div>
          <div class="h-2/3 overflow-hidden flex">
            <sales @salesData="salesData" class="w-full" />
          </div>

          <div
            class="h-1/3 overflow-hidden flex flex-col justify-center items-center"
          >
            <sales-chart class="h-full w-full flex-none" />
          </div>
        </div>
      </div>

      <div class="h-28 flex-none">
        <div class="box h-full flex flex-col relative px-4">
          <!-- <div v-if="!seeit" class="absolute top-0 left-0 h-full w-full z-20 rounded-lg" style="backdrop-filter: blur(10px);"></div> -->
          <div
            class="absolute bg-box-80 z-10 h-full w-full top-0 rounded-lg left-0"
            v-if="loadingSales"
          >
            <loader class=" " />
          </div>
          <div class="box-title">Effectiveness ratio</div>
          <effectiveness-ratio />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { state } from "@/store";
const PipelineStatus = () =>
  import("@/components/Globals/Pipeline/PipelineStatus.vue");
const PipelineBubbles = () =>
  import("@/components/Desktop/Pipeline/PipelineBubbles.vue");
const Sales = () => import("@/components/Desktop/Pipeline/Sales.vue");
const SalesEstimated = () =>
  import("@/components/Desktop/Pipeline/SalesEstimated.vue");
const SalesChart = () => import("@/components/Desktop/Pipeline/SalesChart.vue");
const EffectivenessRatio = () =>
  import("@/components/Desktop/Pipeline/EffectivenessRatio.vue");

export default {
  name: "Pipeline",
  props: ["opportunity"],
  components: {
    PipelineStatus,
    PipelineBubbles,
    Sales,
    SalesEstimated,
    SalesChart,
    EffectivenessRatio,
  },
  data() {
    return {
      limit: this.$options.filters.randomNumber(100, 900) / 10,
      plan: this.$options.filters.randomNumber(155, 9999) / 100,
      loadingStatus: false,
      loadingSales: false,
      loadingEffectiveness: false,
      isObjectivePercentage: false,
    };
  },
  computed: {
    globalParamsPretty() {
      return state.globalParamsPretty;
    },
    userData() {
      return state.user;
    },
    defaultSelectedRol() {
      return _.find(this.userData.roles, {
        role_type_id: this.userData.role_default.role_type_id,
        gui_role_specific_id: this.userData.role_default.gui_role_specific_id,
      });
    },
    user() {
      return state.user;
    },
    salesVsLimit() {
      return this.salesPercent - this.limit;
    },
    params() {
      let params = state.globalParams;
      if (this.$route.params.catId) {
        params = {
          ...params,
          ...{ sectorAmbiciona: this.$route.params.catId },
        };
      }
      return params;
    },
    seeit() {
      if (state.rolSelected.role_type_link != "presidencia") {
        return true;
      } else {
        return false;
      }
    },
    rolSelected() {
      return state.rolSelected;
    },
  },
  methods: {
    salesData(sales) {
      this.isObjectivePercentage = sales.won_with_next_to_win_objective;
    },
  },
};
</script>
